<template>
    <div class="pay-page">
        <img src="../../assets/images/loading.gif" width="100%" alt="">
    </div>
</template>
 
<script>

 
export default {
    data() {
        return {
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$store.state.payType === 'WX') {
                window.location.href = this.$route.query.payHTML.orderForm;//微信是URL地址
            } else {//
                document.body.innerHTML = document.body.innerHTML + this.$route.query.payHTML.orderForm;//支付宝是HTML表单代码
                document.forms[0].submit()
            }
        })
    },
    methods: {
        
    }
}
</script>
 
<style scoped>
 .pay-page{
     height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;
 }
</style>